import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  ArrowLeftOutlined
} from "@ant-design/icons";

import "./index.css";
import { AppContext } from "../../utilities/contexts/AppContext";

function VideoHeader(props:any) {
  const {activeRoute} = props;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { state } = useContext(AppContext);

  const back = () => {
    let returnPath = searchParams.get("return");
    navigate(returnPath ? `${returnPath}` : `/videos`);
  }

  return (
    <div className="DFlex AlignItemsCenter BotName">
      <ArrowLeftOutlined className="IconPadding" onClick={back}/><span className="VideoTitle">{state.activeVideo && state.activeVideo.title ? state.activeVideo.title : "  "}</span>
    </div>
  );
};

export default VideoHeader;
