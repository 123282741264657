import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  DeploymentUnitOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Menu } from "antd";

import "./index.css";
import SubMenuItem from "../SubMenuItem";
import { AppContext } from "../../utilities/contexts/AppContext";

function Navigation(props:any) {
  const {activeRoute} = props;
  const [current, setCurrent] = useState(activeRoute);
  const { state } = useContext(AppContext);
  const [menuItems, setMenuItems] = useState<MenuProps["items"]>([]);
  const navigate = useNavigate();
  useEffect(() => {
    console.log("State Changed", state);
    let menuItems:any = [
      {
        label: "Bots",
        key: "/bot",
        icon: <DeploymentUnitOutlined />,
        className: "W35"
      },
      {
        label: "Videos",
        key: "/videos",
        icon: <YoutubeOutlined />,
        className: "W35"
      }
    ];
    setMenuItems(menuItems)
  }, [state])

  useEffect(() => {
    console.log("Current Route in Navigation",current);
  }, [current]);

  const onClick: MenuProps["onClick"] = (e) => {
    setCurrent(e.key);
    navigate(e.key);
  };

  return (
    <Menu
      onClick={onClick}
      selectedKeys={[current]}
      mode="horizontal"
      items={menuItems}
      className="W100 JustifyContentCenter"
    />
  );
};

export default Navigation;
