import React, { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import './index.css';
import { Col, Divider, Row, Tag } from 'antd';
import { AppContext } from '../../utilities/contexts/AppContext';

function BotSidebar(props:any) {
  const {data} = props;
  const navigate = useNavigate();
  
  const { state, dispatch } = useContext(AppContext);

  const onClick = (channelKey) => {
    navigate(`/bot/${channelKey}`);
  };

  return (
    <div className="BotSidebar DFlex JustifyContentEnd FlexDirectionColumn">
      {
        data.map(function (datum: any, index: any) {
          return <div key={datum.key} className={`BotIndividual PreventSelect ${datum.active ? 'BotActive' : ''}`} onClick={() => onClick(datum.key)}>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      <Col span={4}>
                        <img className="ProfileImage" src={datum.profile_image}/>
                      </Col>
                      <Col span={20} className="DFlex FlexDirectionColumn JustifyContentSpaceBetween">
                        <div className="TextLeft ProfileName">{datum.user.name}</div>
                        <div className="TextLeft">Subscribers: {datum.subscribers} | Videos: {datum.videos}</div>
                        <div className="TextLeft"></div>
                        <div className="TextLeft">{datum.tags.split(',').map(tag => <Tag key={`${datum.key}${tag}`} className={`BotTag ${datum.active ? 'BotTagActive' : ''}`}>{tag.trim()}</Tag>)}</div>
                      </Col>
                    </Row>
                    <Divider className="BotListDivider"/>
                  </div>;
        })
      }
    </div>
  );
}

export default BotSidebar;
