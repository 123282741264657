import React, { createContext, useReducer, useEffect } from 'react';
import { AppContext } from '../contexts/AppContext';
import { AppReducer } from '../reducers/AppReducer';

const AppContextProvider = (props: any) => {
  const [state, dispatch] = useReducer(AppReducer, [], () => {
    const localData = localStorage.getItem('gistrrstatev1d7');
    return localData ? JSON.parse(localData) : {videos: [], chatThread: [], activeChat: {}, activeVideo: {}};
  }); //useReducer accepts a reducer of type (state,action)=> NewState,and returns the new state passed with a dispatch method
  useEffect(() => {
    localStorage.setItem('gistrrstatev1d7', JSON.stringify(state));
  }, [state]);
  return <AppContext.Provider value={{ state, dispatch }}>
            {props.children}
         </AppContext.Provider>;
};

export default AppContextProvider;