export const AppReducer = (state:any, action:any) => {
  switch (action.type) {
    case 'SEARCH_SELECT':
      console.log(action);
      state = {...state, ...{videos: [...state.videos, ...[{title: action.data.title, id: action.data.id}]]}}
      return state;
    case 'SEED_CHAT':
      console.log(action);
      state = {...state, ...{chatThread: action.data.messages}};
      return state;
    case 'SET_ACTIVE_CHAT':
      console.log(action);
      state = {...state, ...{activeChat: action.data.activeChat}};
      return state;
    case 'SET_ACTIVE_VIDEO':
      console.log(action);
      state = {...state, ...{activeVideo: action.data.activeVideo}};
      return state;
    case 'NEW_CHAT_MESSAGE':
      console.log(action);
      let sourceVideoId = action.data.sourceVideoId ? action.data.sourceVideoId : '';
      state = {...state, ...{chatThread: [...state.chatThread, ...[{message: action.data.message, sender: action.data.sender, time: action.data.time, sourceVideoId: sourceVideoId}]]}};
      return state;
    case 'RESET':
      console.log(action);
      state = {videos: [], chatThread: [], activeChat: {}, activeVideo: {}};
      return state;
    default:
      return state;
  }
};