import React, { useState, useEffect, useContext } from "react";
import "./index.css";
import { useNavigate, useParams } from "react-router-dom";
import { AppstoreAddOutlined } from '@ant-design/icons';

import YouTube, { YouTubeProps } from "react-youtube";
import { Button, Checkbox, Col, Modal, Row, Space } from "antd";
import Annotations from "../../components/Annotations";
import Chapters from "../../components/Chapters";
import Summary from "../../components/Summary";
import Chat from "../../components/Chat";
import { AppContext } from "../../utilities/contexts/AppContext";

import {
  getAnnotationByUserVideo,
  getVideoIdByKey,
  getYoutubeDetails,
  getYoutubeVideoSummary,
  insertImageAnnotation,
  insertTextAnnotation,
  insertVideoForUser,
} from "../../utilities/ApiClient";
import TextArea from "antd/es/input/TextArea";
import { CheckboxChangeEvent } from "antd/es/checkbox";

function Video() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentTime, setCurrentTime] = useState(0);
  const [annotationTime, setAnnotationTime] = useState('');
  const [summary, setSummary] = useState("");
  const [loggedInUserId, setLoggedInUserId] = useState("");
  const [currentVideoId, setCurrentVideoId] = useState("");
  const [chapters, setChapters] = useState([]);
  const [annotationInput, setAnnotationInput] = useState("");
  const [annotations, setAnnotations] = useState([]);
  const [chatMessages, setChatMessages] = useState([]);
  const [attachScreentshot, setAttachScreentshot] = useState(false);
  const [videoTargetElement, setVideoTargetElement] = useState<any>({});

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const { state, dispatch } = useContext(AppContext);
  let intervalObject: any;

  useEffect(() => {
    dispatch({
      type: "SET_ACTIVE_VIDEO",
      data: {
        activeVideo: {title: ''}
      },
    });
    let user: any = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user);
      if (!user.loggedIn) {
        navigate("/login");
        return;
      }
    } else {
      navigate("/login");
      return;
    }
    setLoggedInUserId(user.id)
    getYoutubeDetails(id).then((response: any) => {
      
      dispatch({
        type: "SET_ACTIVE_VIDEO",
        data: {
          activeVideo: {title: response.data.results.title}
        },
      });
    }).catch(function (error) {
      console.log(error);
    });
    getVideoIdByKey(id)
      .then((response: any) => {
        setCurrentVideoId(response.data.gistrrv3_video[0].id);
        insertVideoForUser(user.id, response.data.gistrrv3_video[0].id).then((response: any) => {
          
        })
        .catch(function (error) {
          console.log(error);
        });
        getAnnotationByUserVideo(user.id, response.data.gistrrv3_video[0].id).then((response: any) => {
          setAnnotations(response.data.gistrrv3_annotation);
        })
        .catch(function (error) {
          console.log(error);
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setSummary("");
    setChapters([]);
    // getYoutubeVideoSummary(id)
    //   .then((response: any) => {
    //     setSummary(response.data.summary);
    //     setChapters(response.data.chapters);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  }, [id]);

  useEffect(() => {
    console.log(currentTime);
  }, [currentTime]);

  const onPlayerReady: YouTubeProps["onReady"] = (event) => {
    setVideoTargetElement(event.target)
    // event.target.pauseVideo();
  };

  const onPlayerStateChange: YouTubeProps["onStateChange"] = (event) => {
    console.log(event.data);
    switch (event.data) {
      case -1: //unstarted
        break;
      case 0: //ended
        break;
      case 1: //playing
        intervalObject = setInterval(() => {
          setCurrentTime(event.target.getCurrentTime());
        }, 1000);
        break;
      case 2: //paused
        clearInterval(intervalObject);
        break;
      case 3: //buffering
        break;
      case 5: //video cued
        break;
      default:
        break;
    }
  };

  const opts: YouTubeProps["opts"] = {
    height: "100%",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  const showModal = () => {
    videoTargetElement.pauseVideo();
    let annotationTempTime:any = videoTargetElement.getCurrentTime() / 60;
    annotationTempTime = `${Math.floor(annotationTempTime/60).toString()}:${Math.floor(annotationTempTime%60).toString()}:${Math.floor((annotationTempTime - Math.floor(annotationTempTime)) * 60).toString()}`;
    setAnnotationTime(annotationTempTime);
    setOpen(true);
    setAttachScreentshot(false);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    let annotationFunction = attachScreentshot ? insertImageAnnotation : insertTextAnnotation;
    annotationFunction(annotationInput, loggedInUserId, currentVideoId, videoTargetElement.getCurrentTime()).then((response: any) => {
      setOpen(false);
      setConfirmLoading(false);
      setAnnotationInput('');
      let annotationsTemp = [...annotations, ...[{timestamp: videoTargetElement.getCurrentTime(), text: annotationInput, meta_type: {value: attachScreentshot ? 'image' : 'text'}}]].sort((a, b) => a.timestamp - b.timestamp);
      setAnnotations(annotationsTemp);
      videoTargetElement.playVideo();
    })
    .catch(function (error) {
      console.log(error);
    });
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setAnnotationInput('');
    setOpen(false);
    videoTargetElement.playVideo();
  };

  const attachScreenshotChanged = (e: CheckboxChangeEvent) => {
    setAttachScreentshot(e.target.checked);
  };


  return (
    <div className="Video">
      <Row>
        <Col span={24} className="VideoHeight">
          <YouTube
            videoId={id}
            opts={opts}
            onReady={onPlayerReady}
            onStateChange={onPlayerStateChange}
          />
        </Col>
      </Row>

      <Row className="MT32">
        <Col span={24} className="DFlex">
          <Annotations data={annotations}/>
        </Col>
      </Row>

      
      <Button type="primary" icon={<AppstoreAddOutlined />} className="FloatingAnnotationButton" onClick={showModal}>Add annotation</Button>
      <Modal
        title={`Adding annotation at ${annotationTime}`}
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        centered
      >
        <TextArea rows={4} placeholder="Enter text here (max 160 characters)" maxLength={160} onChange={e => setAnnotationInput(e.target.value)} value={annotationInput}/>
        <Checkbox className="MT16" checked={attachScreentshot} onChange={attachScreenshotChanged}>Attach screenshot</Checkbox>
      </Modal>
    </div>
  );
}

export default Video;
