import React, { useEffect, useReducer } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { ConfigProvider, Layout, Space } from "antd";

import HeaderComponent from "./components/Header";
import FooterComponent from "./components/Footer";

import Dashboard from "./pages/Dashboard";

import "./App.css";
import Video from "./pages/Video";
import Login from "./pages/Login";
import Reset from "./pages/Reset";
import Bot from "./pages/Bot";
import BotList from "./pages/BotList";
import VideoList from "./pages/VideoList";

const { Header, Footer, Content } = Layout;

function App() {
  // useEffect(() => {
  //   const node = document.querySelector('.ContentCustom') as HTMLElement;
  //   node.style.marginTop = document.querySelector('.HeaderCustom').clientHeight.toString() + 'px';
  // });
  
  return (
    <ConfigProvider theme={{
      token: {
        // Seed Token
        colorPrimary: 'black'
      },
    }}>
      <div className="App">
        <BrowserRouter>
          <Space direction="vertical" style={{ width: "100%" }} size={[0, 48]}>
            <Layout className="RootLayout">
              <Header className="HeaderBgWhite HeaderCustom">
                <HeaderComponent />
              </Header>
              <Content className="ContentCustom">
                <Routes>
                  <Route path="/">
                    <Route index element={<Dashboard />} />
                    <Route path="video/:id" element={<Video />} />
                    <Route path="videos" element={<VideoList />} />
                    <Route path="bot" element={<BotList />} />
                    <Route path="bot/:id" element={<Bot />} />
                    <Route path="login" element={<Login />} />
                    <Route path="reset" element={<Reset />} />
                  </Route>
                </Routes>
              </Content>
              {/* <Footer>
                <FooterComponent />
              </Footer> */}
            </Layout>
          </Space>
        </BrowserRouter>
      </div>
    </ConfigProvider>
  );
}

export default App;
