import React from "react";
import "./index.css";

import { useLocation } from 'react-router-dom';

import { Col, Row, Spin } from "antd";

function Loader() {
  return (
    <div className="LoaderComponent">
      <Row>
        <Col span={24}>
          <Spin tip="Loading" size="large">
            <div className="content" />
          </Spin>
        </Col>
      </Row>
    </div>
  );
}

export default Loader;
