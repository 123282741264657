import React, { useContext, useEffect } from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../utilities/contexts/AppContext";

function Reset() {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);

  useEffect(() => {
    dispatch({ type: 'RESET', data: { } });
    localStorage.clear()
    setTimeout(() => {
      navigate("/login");
    }, 3000);
  }, []);

  return <div className="Reset"></div>;
}

export default Reset;
