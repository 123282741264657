import { Card } from 'antd';
import "./index.css";
import React, { useContext } from 'react';
import CardHeader from '../CardHeader';
import { AppContext } from '../../utilities/contexts/AppContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { searchYoutube } from '../../utilities/ApiClient';

function ChatBubble(props:any) {
  const {data} = props;
  const { state, dispatch } = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();

  const navigateToVideo = () => {
    // searchYoutube(data.sourceVideoId[0]).then((response) => {
    //   dispatch({ type: 'SEARCH_SELECT', data: { title: response.data.results.videos[0].title , id: data.sourceVideoId[0] } });
      navigate(`/video/${data.sourceVideoId[0]}?return=${location.pathname}`, { replace: true });
    // })
    // .catch(function (error) {
    //   console.log(error);
    // });
  }

  return (
    <div className={"ChatBubble DFlex FlexDirectionColumn " + (data.sender === "bot" ? "BotBubble" : "UserBubble")}>
      <div>{data.message}</div>
      {data.sourceVideoId && data.sourceVideoId.length ? <div>To know more: <a onClick={navigateToVideo}>Click here</a></div> : <></>}
      <span className="BubbleTime">{data.time}</span>
    </div>
  );
}

export default ChatBubble;
