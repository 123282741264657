import React, { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import './index.css';
import Loader from '../Loader';
import { Col, Divider, Row, Tag } from 'antd';
import { AppContext } from '../../utilities/contexts/AppContext';

function VideosSidebar(props:any) {
  const {data} = props;
  const navigate = useNavigate();
  
  const { state, dispatch } = useContext(AppContext);

  const onClick = (key) => {
    navigate(`/video/${key}`);
  };
  console.log(data)
  return (
    <div className="VideosSidebar DFlex JustifyContentEnd FlexDirectionColumn">
      {
        data.map(function (datum: any, index: any) {
          return <div key={datum.key} className={`VideosIndividual PreventSelect ${datum.active ? 'VideosActive' : ''}`} onClick={() => onClick(datum.key)}>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      <Col span={4}>
                        <img className="VideoImage" src={datum.thumbnail}/>
                      </Col>
                      <Col span={20} className="DFlex FlexDirectionColumn JustifyContentSpaceBetween">
                        {/* <div className="TextLeft ProfileName">{datum.title}</div> */}
                        <div className="TextLeft">{datum.title}</div>
                        <div className="TextLeft">{datum.channel_name}</div>
                        {/* <div className="TextLeft">{datum.tags.split(',').map(tag => <Tag key={`${datum.key}${tag}`} className={`VideosTag ${datum.active ? 'VideosTagActive' : ''}`}>{tag.trim()}</Tag>)}</div> */}
                      </Col>
                    </Row>
                    <Divider className="VideosListDivider"/>
                  </div>;
        })
      }
    </div>
  );
}

export default VideosSidebar;
