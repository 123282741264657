import { Button, Card, Input, Space } from "antd";
import "./index.css";
import React, { useState, useContext, useEffect } from "react";

import _, { debounce } from 'underscore';
import { AppContext } from '../../utilities/contexts/AppContext';

function ChatInput(props: any) {
  const { submitInput, typing } = props;
  const [chatInputText, setChatInputText] = useState('');
  const { state, dispatch } = useContext(AppContext);

  useEffect(() => {
    console.log("Currently typing:",typing);
  }, [typing]);

  const onChatInputChanged = (event: any) => {
    setChatInputText(event.target.value)
  }

  const submitChatInput = () => {
    submitInput(chatInputText)
    setChatInputText('');
  }

  return (
    <div className="ChatInput DFlex W100 FlexDirectionColumn">
      <Space.Compact className="W100">
        <Input className="ChatInputText" placeholder={typing ? `${state.activeChat && state.activeChat.user ? state.activeChat.user.name : '  '} is typing...` : "Ask away!!"} value={chatInputText} disabled={typing} onPressEnter={submitChatInput} onChange={onChatInputChanged}/>
        <Button className="ChatInputButton" disabled={typing} type="primary" onClick={submitChatInput}>{typing ? <div className="typing">
          <span></span>
          <span></span>
          <span></span>
        </div> : <>Submit</>}</Button>
      </Space.Compact>
    </div>
  );
}

export default ChatInput;
