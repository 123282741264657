import { Card } from 'antd';
import "./index.css";
import React from 'react';

function CardHeader(props:any) {
  const {title} = props;
  return (
    <div className="CardHeader DFlex">
      {title}
    </div>
  );
}

export default CardHeader;
