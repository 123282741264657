import React, { useContext, useEffect, useState } from "react";
import "./index.css";
import { useNavigate, useParams } from "react-router-dom";
import { Layout } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import BotSidebar from "../../components/BotSidebar";
import { AppContext } from "../../utilities/contexts/AppContext";
import Chat from "../../components/Chat";
import {
  getBot,
  getBotChat,
  getChatResponse,
  insertChat,
  insertVideos,
} from "../../utilities/ApiClient";

import { createClient, SubscribePayload } from "graphql-ws";

function Bot() {
  const client = createClient({
    url: "ws://striking-treefrog-63.hasura.app/v1/graphql",
    connectionParams: {
      "x-hasura-admin-secret":
        "mplk4mZ1LBn89f04xDkAds82wS7apR0V64pXeUhuuV3rmkAhspcdMf9IeTgcVhYn",
    },
  });
  const { id } = useParams();
  const navigate = useNavigate();
  const [bot, setBot] = useState<any>({});
  const [typing, setTyping] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const [loggedInUserId, setLoggedInUserId] = useState("");

  const { state, dispatch } = useContext(AppContext);

  const [chatMessages, setChatMessages] = useState([]);

  const insertChatAsync = (receiver, sender, text, sourceVideoIds) => {
    if(sourceVideoIds.length) {
      insertVideos(sourceVideoIds).then((response) => {
        insertChat(receiver, sender, text, sourceVideoIds).then((response) => {})
          .catch(function (error) {});
      })
        .catch(function (error) {});
    }
    else{
      insertChat(receiver, sender, text, sourceVideoIds).then((response) => {})
        .catch(function (error) {});
    }
  };

  const sanitizeMessages = (rawMessages) => rawMessages.sort((a, b) => a.cursor_id - b.cursor_id).map((message:any) => ({message: message.text, sender: message.userBySenderUserId.meta_type.value, time: message.created_at, sourceVideoId: message.source_video_id}))

  const submitInput = (input) => {
    dispatch({
      type: "NEW_CHAT_MESSAGE",
      data: {
        message: input,
        sender: "user",
        time: new Date().toLocaleString(),
        sourceVideoId: ''
      },
    });
    setTyping(true);
    insertChatAsync(bot.user.id, loggedInUserId, input, []);

    getChatResponse(input, bot.id)
      .then((response) => {
        dispatch({
          type: "NEW_CHAT_MESSAGE",
          data: {
            message: response.data.response,
            sender: "bot",
            time: new Date().toLocaleString(),
            sourceVideoId: response.data.source_video_id
          },
        });

        insertChatAsync(loggedInUserId, bot.user.id, response.data.response, response.data.source_video_id);
        setTyping(false);
      })
      .catch(function (error) {
        // console.log(error);
        setTyping(false);
      });
  };

  useEffect(() => {
    
    dispatch({
      type: "SET_ACTIVE_CHAT",
      data: {
        activeChat: ''
      },
    });
    dispatch({
      type: "SEED_CHAT",
      data: {
        messages: []
      },
    });
    let user: any = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user);
      if (!user.loggedIn) {
        navigate("/login");
        return;
      }
    }
    else {
      navigate("/login");
      return;
    }
    setLoading(true);
    setLoggedInUserId(user.id)
    getBot(id)
      .then((response) => {
        setBot(response.data.gistrrv3_channel[0]);
        dispatch({
          type: "SET_ACTIVE_CHAT",
          data: {
            activeChat: response.data.gistrrv3_channel[0]
          },
        });
        getBotChat(user.id, response.data.gistrrv3_channel[0].user.id, 100000, 10).then((response) => {
          
          dispatch({
            type: "SEED_CHAT",
            data: {
              messages: sanitizeMessages(response.data.gistrrv3_chat)
            },
          });
        })
        .catch(function (error) {
          console.log(error);
        })
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setChatMessages(state.chatThread);
  }, [state]);

  return (
    <div className="Bot H100">
      <Layout className="AllBotLayout">
        <Content className="BotContent">
          <Chat
            data={chatMessages}
            header={false}
            botId={bot.id}
            botApiPath={bot.api_path}
            submitInput={submitInput}
            typing={typing}
          />
        </Content>
      </Layout>
    </div>
  );
}

export default Bot;
