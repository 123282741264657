import axios from "axios";

// const BASE_URL = "http://localhost:5000/";
const BASE_URL = "https://api.gistrr.com/";
// const PROXY_BASE_URL = "http://localhost:5000/data-proxy/";
const PROXY_BASE_URL = "https://api.gistrr.com/data-proxy/";

export function searchYoutube(query: any) {
  let config = {
    method: "get",
    url: `${BASE_URL}search?q=${query.replace(/ /g, "+")}`,
    headers: {},
  };

  return axios(config);
}

export function getYoutubeDetails(videoId: any) {
  let data = JSON.stringify({
    query: videoId,
  });
  let config = {
    method: "post",
    url: `${BASE_URL}details`,
    headers: {'Content-Type': 'application/json'},
    data: data,
  };

  return axios(config);
}

export function getYoutubeVideoSummary(videoId: string|undefined) {
  let data = JSON.stringify({
    video_link: `https://www.youtube.com/watch?v=${videoId}`,
  });
  let config = {
    method: "post",
    url: `${BASE_URL}sum`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config);
}

export function getChatResponse(query: string|undefined, channel_id: string|undefined) {
  let data = JSON.stringify({"query": query, "channel_id": channel_id});

  let config = {
    method: 'post',
    url: `${BASE_URL}chat`,
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };

  return axios(config)
}

export function getChatBotResponse(query: string|undefined, botApiPath: string|undefined) {
  let data = JSON.stringify({"query": query});

  let config = {
    method: 'post',
    url: `${BASE_URL}/${botApiPath}`,
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };

  return axios(config)
}


export function getBotList() {
  let config = {
    method: 'get',
    url: `${PROXY_BASE_URL}getbots`,
    headers: { 
            'Content-Type': 'application/json'
    }
  };
  
  return axios(config)
}


export function getBot(channelId) {
  let config = {
    method: 'get',
    url: `${PROXY_BASE_URL}getbot/${channelId}`,
    headers: { 
            'Content-Type': 'application/json'
    }
  };
  
  return axios(config)
}

export function getBotChat(receiverUserId, senderUserId, lastCursor, pageSize) {
  let data = JSON.stringify({"receiver_user_id": receiverUserId,"sender_user_id": senderUserId,"last_cursor": lastCursor,"page_size": pageSize});
  let config = {
    method: 'post',
    url: `${PROXY_BASE_URL}getbotchat`,
    headers: { 
            'Content-Type': 'application/json'
    },
    data : data
  };
  
  return axios(config)
}

export function insertChat(receiverUserId, senderUserId, text, sourceVideoId) {
  let data = JSON.stringify({"receiver_user_id": receiverUserId,"sender_user_id": senderUserId,"text": text, "source_video_id": sourceVideoId});
  let config = {
    method: 'post',
    url: `${PROXY_BASE_URL}insertchat`,
    headers: { 
            'Content-Type': 'application/json'
    },
    data : data
  };
  
  return axios(config)
}

export function insertVideos(sourceVideoIds) {
  let data = JSON.stringify({"objects": sourceVideoIds.map((it:any) => ({"key": it}))});
  let config = {
    method: 'post',
    url: `${PROXY_BASE_URL}insertvideos`,
    headers: { 
            'Content-Type': 'application/json'
    },
    data : data
  };
  
  return axios(config)
}

export function insertUser(name, email, password, profile_image_url) {
  let data = JSON.stringify({name, email, password, profile_image_url});
  let config = {
    method: 'post',
    url: `${PROXY_BASE_URL}insertuser`,
    headers: { 
            'Content-Type': 'application/json'
    },
    data : data
  };
  
  return axios(config)
}

export function getVideoIdByKey(key) {
  let data = JSON.stringify({key});
  let config = {
    method: 'post',
    url: `${PROXY_BASE_URL}getvideoidbykey`,
    headers: { 
            'Content-Type': 'application/json'
    },
    data : data
  };
  
  return axios(config)
}

export function insertVideoForUser(user_id, video_id) {
  let data = JSON.stringify({user_id, video_id});
  let config = {
    method: 'post',
    url: `${PROXY_BASE_URL}insertvideoforuser`,
    headers: { 
            'Content-Type': 'application/json'
    },
    data : data
  };
  
  return axios(config)
}

export function getVideosByUser(user_id) {
  let data = JSON.stringify({user_id: {_eq: user_id}});
  let config = {
    method: 'post',
    url: `${PROXY_BASE_URL}getvideosbyuser`,
    headers: { 
            'Content-Type': 'application/json'
    },
    data : data
  };
  
  return axios(config)
}

export function insertTextAnnotation(text, user_id, video_id, timestamp) {
  let data = JSON.stringify({text, user_id, video_id, timestamp});
  let config = {
    method: 'post',
    url: `${PROXY_BASE_URL}inserttextannotation`,
    headers: { 
            'Content-Type': 'application/json'
    },
    data : data
  };
  
  return axios(config)
}

export function insertImageAnnotation(text, user_id, video_id, timestamp) {
  let data = JSON.stringify({text, user_id, video_id, timestamp});
  let config = {
    method: 'post',
    url: `${PROXY_BASE_URL}insertimageannotation`,
    headers: { 
            'Content-Type': 'application/json'
    },
    data : data
  };
  
  return axios(config)
}

export function getAnnotationByUserVideo(user_id, video_id) {
  let data = JSON.stringify({user_id, video_id});
  let config = {
    method: 'post',
    url: `${PROXY_BASE_URL}getannotationbyuservideo`,
    headers: { 
            'Content-Type': 'application/json'
    },
    data : data
  };
  
  return axios(config)
}

