import React, { useContext, useEffect, useState } from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { Layout } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import BotSidebar from "../../components/BotSidebar";
import { AppContext } from "../../utilities/contexts/AppContext";
import Chat from "../../components/Chat";
import { getBotList } from "../../utilities/ApiClient";
import Loader from "../../components/Loader";

function BotList() {
  const navigate = useNavigate();
  const [bots, setBots] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let user: any = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user);
      if (!user.loggedIn) {
        navigate("/login");
        return;
      }
    }
    else {
      navigate("/login");
      return;
    }
    setLoading(true);
    getBotList().then((response) => {
      setBots(response.data.gistrrv3_channel);
      setLoading(false);
    })
    .catch(function (error) {
      console.log(error);
      setLoading(false);
    });
  }, []);

  return (
    <div className="Bot H100">
      <Layout className="AllBotLayout">
        <Content className="BotSidebar">{!loading ? <BotSidebar data={bots}/> : <Loader />}</Content>
      </Layout>
    </div>
  );
}

export default BotList;
