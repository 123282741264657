import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAFRIC_sKYTEyYsXyGfCYK-a7PYf8QGm-A",
  authDomain: "gistrr-a10e2.firebaseapp.com",
  projectId: "gistrr-a10e2",
  storageBucket: "gistrr-a10e2.appspot.com",
  messagingSenderId: "703242511198",
  appId: "1:703242511198:web:14755a40ef1d66540865cf",
  measurementId: "G-TXQ2PSEQSK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export {auth, analytics, provider}