import React, { useState, useEffect, useContext } from "react";
import "./index.css";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Card, Form, Input } from "antd";
import CardHeader from "../../components/CardHeader";

import { auth, analytics, provider } from "../../FirebaseConfig";
import { signInWithPopup, signInWithRedirect } from "firebase/auth"
import { insertUser } from "../../utilities/ApiClient";

type LoginForm = {
  email?: string;
  password?: string;
};

function Login() {
  const navigate = useNavigate();
  
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    let user: any = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user);
      if (user.loggedIn) {
        navigate("/bot");
      }
    }
  }, []);

  const handleClick = () => {
    setLoading(true);
    signInWithPopup(auth, provider).then((data) => {
      insertUser(data.user.displayName, data.user.email, data.user.uid, data.user.photoURL)
      .then((response) => {
        if(response.data.insert_gistrrv3_user_one === null) {
          setLoading(false);
          setError(true)
        }
        else{
          localStorage.setItem("user", JSON.stringify({...{id: response.data.insert_gistrrv3_user_one.id,name: response.data.insert_gistrrv3_user_one.name, email: response.data.insert_gistrrv3_user_one.email, profileImageUrl: response.data.insert_gistrrv3_user_one.profile_image_url}, ...{loggedIn: true}}));
          navigate("/bot");
        }
      })
      .catch(function (error) {
        console.log(error);
      })
    })
  };

  return (
    <div className="Login GradientBorder">
      <Card bordered={true} className="LoginCard DFlex">
        <h3>Login</h3>
        <Button type="primary" onClick={handleClick} loading={loading}>
          {loading ? 'Logging' : 'Log'} in with Google 
        </Button>
        {error ? <div className="LoginError">An error occured while trying to login. Please try again after some time or with a different google account</div> : <></>}
      </Card>
    </div>
  );
}

export default Login;
