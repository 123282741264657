import React, { useEffect, useState } from "react";
import "./index.css";

import { useLocation } from 'react-router-dom';

import { Col, Row } from "antd";

import Navigation from "../Navigation";

import {
  UserOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Search from "../Search";
import Account from "../Account";
import BotHeader from "../BotHeader";
import VideoHeader from "../VideoHeader";

function HeaderComponent() {
  const location = useLocation();
  const [profileName, setProfileName] = useState('');
  const headerTabsVisibleRoutes = ['/bot', '/videos'];

  useEffect(() => {
    console.log("Location changed", location);
    let user: any = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user);
      if (user.loggedIn) {
        setProfileName(user.name.substring(0,user.name.search(" ")))
      }
    }
  }, [location.pathname])
  
  return (
    <div className="HeaderComponent">
      <Row>
        <Col span={12} className="DFlex JustifyContentStart"><span className="LogoStart">G</span><span className="LogoRest">istrr</span></Col>
        <Col span={12}><Row className="DFlex JustifyContentEnd"><Col span={24} className="DFlex JustifyContentEnd"><Account profileName={profileName}/></Col></Row></Col>
      </Row>
      <Row>
        <Col span={24} className={`DFlex ${headerTabsVisibleRoutes.indexOf(location.pathname) !== -1 ? 'JustifyContentCenter' : 'JustifyContentStart'}`}>
        {headerTabsVisibleRoutes.indexOf(location.pathname) !== -1 ? <Navigation activeRoute={location.pathname}/> : <></>}
        {location.pathname.includes('/bot/') ? <BotHeader/> : <></>}
        {location.pathname.includes('/video/') ? <VideoHeader /> : <></>}
        </Col>
      </Row>
    </div>
  );
}

export default HeaderComponent;
