import React, { useContext, useEffect, useState } from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { Layout } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import VideosSidebar from "../../components/VideosSidebar";
import { AppContext } from "../../utilities/contexts/AppContext";
import Chat from "../../components/Chat";
import { getVideosByUser, getYoutubeDetails } from "../../utilities/ApiClient";
import Loader from "../../components/Loader";

function VideoList() {
  const navigate = useNavigate();
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let user: any = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user);
      if (!user.loggedIn) {
        navigate("/login");
        return;
      }
    }
    else {
      navigate("/login");
      return;
    }
    setLoading(true);
    getVideosByUser(user.id).then((response) => {
      Promise.all(response.data.gistrrv3_video_log.map(it => getYoutubeDetails(it.video.key))).then((allResponse:any) => {
        setVideos(allResponse.map(it => it.data.results) );
        setLoading(false);
      }).catch(function (error) {})
    })
    .catch(function (error) {
      console.log(error);
      setLoading(false);
    });
  }, []);

  return (
    <div className="Videos H100">
      <Layout className="AllVideosLayout">
        <Content className="VideosSidebar">{!loading ? <VideosSidebar data={videos}/> : <Loader />}</Content>
      </Layout>
    </div>
  );
}

export default VideoList;
