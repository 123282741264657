import { Card, Col, Divider, Row } from 'antd';
import "./index.css";
import React from 'react';
import "./index.css";
import CardHeader from '../CardHeader';

function Annotations(props:any) {
  const {data} = props;

  const sanitizeTimestamp = (timestamp) => {
    let annotationTempTime:any = timestamp / 60;
    annotationTempTime = `${Math.floor(annotationTempTime/60).toString()}:${Math.floor(annotationTempTime%60).toString()}:${Math.floor((annotationTempTime - Math.floor(annotationTempTime)) * 60).toString()}`;
    return annotationTempTime;
  }

  return (
    <div className="Annotations DFlex W100">
      <CardHeader title="Annotations" />
      <Card bordered={true} className="AnnotationCard DFlex">
        {
          data.map(function (datum: any, index: any) {
            return <Row>
              <Col span={8} className='DFlex JustifyContentStart'>{sanitizeTimestamp(datum.timestamp)}</Col>
              <Col span={16} className='DFlex JustifyContentEnd'>{datum.text}</Col>
              <Divider />
            </Row>
          })
        }
      </Card>
    </div>
  );
}

export default Annotations;
