import { Card } from "antd";
import "./index.css";
import React, { useEffect, useRef } from "react";
import CardHeader from "../CardHeader";
import ChatInput from "../ChatInput";
import ChatBubble from "../ChatBubble";
import { v4 as uuidv4 } from 'uuid';

function Chat(props: any) {
  const { data, submitInput, typing } = props;
  const mounted = useRef();

  const bottomEl = useRef(null);

  const scrollToBottom = () => {
    bottomEl?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    console.log(data);
      scrollToBottom()
  }, [data]);

  return (
    <div className="Chat DFlex W100 H100">
      <ChatInput submitInput={submitInput} typing={typing}/>
      <Card bordered={true} id="chatContainer" className="ChatCard DFlex">
        {data.map(function (datum: any, index: any) {
          return <ChatBubble data={datum} key={uuidv4()}/>;
        })}
        <div ref={bottomEl}></div>
      </Card>
    </div>
  );
}

export default Chat;
