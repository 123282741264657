import React, { useEffect } from 'react';
import './index.css';
import { useNavigate } from 'react-router-dom';

function Dashboard() {
  const navigate = useNavigate();

  useEffect(() => {
    // let user: any = localStorage.getItem("user");
    // if (user) {
    //   user = JSON.parse(user);
    //   if (!user.loggedIn) {
    //     navigate("/login");
    //   }
    // }
    // else {
    //   navigate("/login");
    // }
    navigate(`/bot`);
  }, []);
  
  return (
    <div className="Dashboard W75 H100 MH1by8 DFlex AlignItemsCenter JustifyContentCenter">
        Gistrr lets you interact with your watched You-tube videos. Start exploring videos by typing in the search bar above.
    </div>
  );
}

export default Dashboard;
