import React, { useEffect, useState } from 'react';
import './index.css';
import { Dropdown, Space } from 'antd';
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
const items: MenuProps['items'] = [
  {
    key: '1',
    label: (
      <a href="/reset">
        Logout
      </a>
    ),
  }
];

function Account(props:any) {
  const {profileName} = props;

  return profileName ? <Dropdown menu={{ items }}>
    <div onClick={(e) => e.preventDefault()}>
      <Space>
        <div className="ProfileName">Hi {profileName}</div>
        <DownOutlined />
      </Space>
    </div>
  </Dropdown> : <></>;
}

export default Account;
