import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  ArrowLeftOutlined
} from "@ant-design/icons";

import "./index.css";
import { AppContext } from "../../utilities/contexts/AppContext";

function BotHeader(props:any) {
  const {activeRoute} = props;
  const navigate = useNavigate();
  const { state } = useContext(AppContext);

  const back = () => {
    navigate(`/bot`);
  }

  return (
    <div className="DFlex AlignItemsCenter BotName">
      <ArrowLeftOutlined className="IconPadding" onClick={back}/><img src={state.activeChat && state.activeChat.profile_image ? state.activeChat.profile_image : '  '} className="AccountThumbnail"/>{state.activeChat && state.activeChat.user ? state.activeChat.user.name : '  '}
    </div>
  );
};

export default BotHeader;
